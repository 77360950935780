/* src/styles.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  :root {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9;
    --chart-1: 240 1% 42%;
    --chart-2: 240 1% 74%;
    --chart-3: 240 2% 26%;
    --chart-4: 240 1% 42%;
    --chart-5: 240 1% 58%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9;
    --chart-1: 359 2% 90%;
    --chart-2: 240 1% 74%;
    --chart-3: 240 1% 58%;
    --chart-4: 240 1% 42%;
    --chart-5: 240 2% 26%;
    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;

    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #content {
    margin: 10px 15px;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  .dashboard-tab {
    width: 100%;
  }

  .dashboard-card {
    margin: 10px 0;
  }

  .margin-0 {
    margin: 0 !important;
  }
}

.privacy {
  body {
    background: #fff;
    color: #000;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 28px;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li,
  p {
    margin: 0 0 16px;
  }

  h1 {
    font-size: 40px;
    line-height: 60px;
  }

  h1,
  h2 {
    font-weight: 700;
  }

  h2 {
    font-size: 32px;
    line-height: 48px;
  }

  h3 {
    font-size: 24px;
    line-height: 36px;
  }

  h3,
  h4 {
    font-weight: 700;
  }

  h4 {
    font-size: 20px;
    line-height: 30px;
  }

  h5,
  h6 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
  }

  a {
    text-decoration: none;
    cursor: pointer;
    color: #000;
  }

  a:hover,
  a[rel~="nofollow"] {
    text-decoration: underline;
  }

  a[rel~="nofollow"] {
    color: #008461;
  }

  a[rel~="nofollow"]:hover {
    text-decoration: none;
  }

  .visible {
    display: block;
  }

  .hidden {
    display: none;
  }

  .page {
    width: 100%;
  }

  .container {
    position: relative;
    width: 90%;
    max-width: 1024px;
    margin: 0 auto;
  }

  .header {
    color: #000;
    padding: 16px 0;
    margin: 0 0 16px;
  }

  .header .title {
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    margin: 0;
  }

  .translations-list-container {
    padding-bottom: 8px;
    margin: 0 0 16px;
  }

  .translations-list-container .translations-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .translations-list-container .translations-list .translations-list-item {
    display: inline-block;
    padding: 0;
    margin: 0 8px 8px 0;
    font-weight: 700;
    color: #008461;
  }

  .translations-list-container .translations-list .translations-list-item a {
    display: inline-block;
    color: #008461;
    border: 2px solid #008461;
    border-radius: 4px;
    padding: 4px 8px;
  }

  .translations-content-container {
    padding-top: 16px;
    border-top: 1px solid #eee;
  }

  .footer {
    border-top: 1px solid #eee;
    margin: 32px 0 0;
    padding: 16px 0;
  }
}

.dashboard-card {
  .recharts-wrapper {
    max-width: 96% !important;
    margin-left: 10px;
  }
}

.otp-form {
  .pin-input {
    display: flex;
    justify-content: center;
  }

  .rounded-md {
    margin: 0 10px;
  }
}
